import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useContext, useEffect, useMemo, useState } from "react";
import { Badge } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Table from "./Table";
import Layout from "../../Layouts";
import AdminContext from "../../Services/Admin";

const schema = yup
  .object({
    name: yup.string().required(),
  })
  .required();

function ChapterPage(params) {
  const { loading, getAllMaster, addMaster, addMasterForm } =
    useContext(AdminContext);
  const [data, setData] = useState([]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const columns = useMemo(
    () => [
      {
        accessorKey: "chapter_number",
        header: "No Chapter",
      },
      {
        accessorKey: "name",
        header: "Nama Chapter",
      },
      {
        accessorKey: "pic_name",
        header: "Nama PIC",
      },
      {
        accessorKey: "pic_phone_number",
        header: "Kontak PIC",
      },
      {
        accessorKey: "logo_file",
        header: "Logo",
        Cell: ({ cell }) =>
          cell && (
            <img
              alt=""
              width={"200px"}
              height={"100%"}
              src={process.env.REACT_APP_FILE_URL + cell.getValue()}
            />
          ),
      },
      {
        accessorKey: "status",
        header: "Status",
        Cell: ({ cell }) => {
          let status = "Non Active";
          if (cell.getValue()==="1") {
            status = "Active";
          }
          return (
            <Badge bg={status === "Active" ? "success" : "danger"}>
              {status}
            </Badge>
          );
        },
      },
    ],
    []
  );

  const bodyForm = () => {
    return (
      <>
        <div className="mb-3">
          <label htmlFor="chapter_number" className="form-label">
            Nomor Chapter
          </label>
          <input
            type="text"
            id="chapter_number"
            className="form-control"
            {...register("chapter_number")}
            placeholder="Enter chapter number"
          />
          <small className="text-danger">
            {errors.chapter_number?.message}
          </small>
        </div>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Nama Chapter
          </label>
          <input
            type="text"
            id="name"
            className="form-control"
            {...register("name")}
            placeholder="Enter chapter name"
          />
          <small className="text-danger">{errors.name?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="pic_name" className="form-label">
            Nama PIC Chapter
          </label>
          <input
            type="text"
            id="pic_name"
            className="form-control"
            {...register("pic_name")}
            placeholder="Enter chapter pic name"
          />
          <small className="text-danger">{errors.pic_name?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="pic_phone_number" className="form-label">
            Nomor PIC Chapter
          </label>
          <input
            type="text"
            id="pic_phone_number"
            className="form-control"
            {...register("pic_phone_number")}
            placeholder="Enter chapter pic phone number"
          />
          <small className="text-danger">
            {errors.pic_phone_number?.message}
          </small>
        </div>
        <div className="mb-3">
          <label htmlFor="pic_address" className="form-label">
            Alamat PIC Chapter
          </label>
          <input
            type="text"
            id="pic_address"
            className="form-control"
            {...register("pic_address")}
            placeholder="Enter chapter pic address"
          />
          <small className="text-danger">{errors.pic_address?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="chapter_date_of_establishment" className="form-label">
            Tanggal Berdiri Chapter
          </label>
          <input
            type="date"
            id="chapter_date_of_establishment"
            className="form-control"
            {...register("chapter_date_of_establishment")}
            placeholder="Enter chapter chapter date of establishment"
          />
          <small className="text-danger">
            {errors.chapter_date_of_establishment?.message}
          </small>
        </div>

        <div className="mb-3">
          <label htmlFor="logo_file" className="form-label">
            Logo
          </label>
          <input
            type="file"
            id="logo_file"
            className="form-control"
            {...register("logo_file")}
          />
          <small className="text-danger">{errors.logo_file?.message}</small>
        </div>

        <div className="mb-3">
          <label htmlFor="status" className="form-label">
            Status
          </label>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="status"
              value="true"
              style={{ minWidth: "50px" }}
              {...register("status")}
            />
            <label className="form-check-label small ps-2" htmlFor="status">
              Active
            </label>
          </div>
          <small className="text-danger">{errors.status?.message}</small>
        </div>
      </>
    );
  };

  const getData = async () => {
    const newData = await getAllMaster("chapter");
    if (newData) {
      setData(newData);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Layout title="Data Chapter">
      <Table
        columns={columns}
        data={data}
        getData={getData}
        getAllAction={getAllMaster}
        addAction={addMaster}
        addActionForm={addMasterForm}
        bodyForm={bodyForm}
        handleSubmit={handleSubmit}
        reset={reset}
        loading={loading}
        manualPagination
      />
    </Layout>
  );
}

export default ChapterPage;
