import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useContext, useEffect, useMemo, useState } from "react";
import { Badge } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import Table from "./Table";
import Layout from "../../Layouts";
import AdminContext from "../../Services/Admin";
import WYSIWYGEditor from "../../Components/Wysiwyg";

const schema = yup
  .object({
    name: yup.string().required(),
    short_description: yup.string().required(),
    description: yup.string().required(),
  })
  .required();

const defaultValues = {
  name: "",
  short_description: "",
  description: "",
  photo_file: "",
  status: "",
};

function NewsPage(params) {
  const { loading, getAllMaster, addMaster, addMasterForm } =
    useContext(AdminContext);
  const [data, setData] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), defaultValues: defaultValues });

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Nama Berita",
      },
      {
        accessorKey: "photo_file",
        header: "Foto",
        Cell: ({ cell }) =>
          cell && (
            <img
              alt=""
              width={"200px"}
              height={"100%"}
              src={process.env.REACT_APP_FILE_URL + cell.getValue()}
            />
          ),
      },
      {
        accessorKey: "updated_at",
        header: "Last Updated",
        Cell: ({ cell }) => moment(cell.getValue()).locale("id").format("LLL"),
      },
      {
        accessorKey: "status",
        header: "Status",
        Cell: ({ cell }) => {
          let status = "Non Active";
          if (cell.getValue()==="1") {
            status = "Active";
          }
          return (
            <Badge bg={status === "Active" ? "success" : "danger"}>
              {status}
            </Badge>
          );
        },
      },
    ],
    []
  );

  const bodyForm = () => {
    return (
      <>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Name
          </label>
          <input
            type="text"
            id="name"
            className="form-control"
            {...register("name")}
            placeholder="Enter news name"
          />
          <small className="text-danger">{errors.name?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="short_description" className="form-label">
            Short Description
          </label>
          <textarea
            type="text"
            id="short_description"
            className="form-control"
            {...register("short_description")}
            placeholder="Enter destination news description"
          ></textarea>
          <small className="text-danger">
            {errors.short_description?.message}
          </small>
        </div>
        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Description
          </label>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <WYSIWYGEditor onChange={field.onChange} value={field.value} />
            )}
          />
          <small className="text-danger">{errors.description?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="photo_file" className="form-label">
            Photo News
          </label>
          <input
            type="file"
            id="photo_file"
            className="form-control"
            {...register("photo_file")}
          />
          <small className="text-danger">{errors.photo_file?.message}</small>
        </div>

        <div className="mb-3">
          <label htmlFor="status" className="form-label">
            Status
          </label>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="status"
              value="true"
              style={{ minWidth: "50px" }}
              {...register("status")}
            />
            <label className="form-check-label small ps-2" htmlFor="status">
              Active
            </label>
          </div>
          <small className="text-danger">{errors.status?.message}</small>
        </div>
      </>
    );
  };

  const getData = async () => {
    const newData = await getAllMaster("news");
    if (newData) {
      setData(newData);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Layout title="Data Berita">
      <Table
        columns={columns}
        data={data}
        getData={getData}
        getAllAction={getAllMaster}
        addAction={addMaster}
        addActionForm={addMasterForm}
        bodyForm={bodyForm}
        handleSubmit={handleSubmit}
        reset={reset}
        loading={loading}
        defaultValues={defaultValues}
      />
    </Layout>
  );
}

export default NewsPage;
