import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AuthState from "../Services/Auth/state.js";
import AdminState from "../Services/Admin/state.js";
import PrivateRoute from "./PrivateRoutes";
import UserPage from "../Pages/Users";
import LoginPage from "../Pages/Auth/Login";
import NewsPage from "../Pages/News/index.js";
import ChapterPage from "../Pages/Chapter/index.js";
import MemberPage from "../Pages/Member/index.js";
import CategoryGalleryPage from "../Pages/CategoryGallery/index.js";
import GalleryPage from "../Pages/Gallery/index.js";
import AgendaPage from "../Pages/Agenda/index.js";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const AppRoutes = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <AuthState>
        <AdminState>
          <BrowserRouter>
            <Routes>
              <Route>
                <Route path="/" element={<LoginPage />} />
                <Route path="login" element={<LoginPage />} />

                <Route element={<PrivateRoute />}>
                  <Route path="master">
                    <Route path="user" element={<UserPage />} />
                    <Route path="chapter" element={<ChapterPage />} />
                    <Route path="member" element={<MemberPage />} />
                    <Route path="news" element={<NewsPage />} />
                    <Route
                      path="category-gallery"
                      element={<CategoryGalleryPage />}
                    />
                    <Route path="gallery/:id" element={<GalleryPage />} />
                    <Route path="agenda" element={<AgendaPage />} />
                  </Route>
                </Route>
              </Route>
            </Routes>
          </BrowserRouter>
          <ToastContainer />
        </AdminState>
      </AuthState>
    </LocalizationProvider>
  );
};

export default AppRoutes;
