import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useContext, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Table from "./Table";
import Layout from "../../Layouts";
import AdminContext from "../../Services/Admin";
import Select from "react-select";
import { useParams } from "react-router-dom/dist";

const schema = yup
  .object({
    name: yup.string().required(),
  })
  .required();

function GalleryPage(params) {
  const { loading, getAllMaster, addMaster, addMasterForm } =
    useContext(AdminContext);
  const [data, setData] = useState([]);
  const [dataCategory, setDataCategory] = useState([]);
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Nama Gambar",
      },
      {
        accessorKey: "category.name",
        header: "Kategori",
      },
      {
        accessorKey: "photo_file",
        header: "Gambar",
        Cell: ({ cell }) =>
          cell && (
            <img
              alt=""
              width={"200px"}
              height={"100%"}
              src={process.env.REACT_APP_FILE_URL + cell.getValue()}
            />
          ),
      },
    ],
    []
  );

  const bodyForm = () => {
    return (
      <>
        <div className="mb-3">
          <label htmlFor="m_gallery_categories_id" className="form-label">
            Kategori
          </label>
          <Controller
            control={control}
            name="m_gallery_categories_id"
            render={({ field: { onChange, value, ref } }) => (
              <Select
                ref={ref}
                value={dataCategory.find((c) => c.value === value)}
                onChange={(val) => {
                  onChange(val.value);
                }}
                options={dataCategory}
                className="form-control p-0 border-0"
              />
            )}
          />
          <small className="text-danger">
            {errors.m_gallery_categories_id?.message}
          </small>
        </div>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Nama Gambar
          </label>
          <input
            type="text"
            id="name"
            className="form-control"
            {...register("name")}
            placeholder="Enter name"
          />
          <small className="text-danger">{errors.name?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="photo_file" className="form-label">
            Gambar
          </label>
          <input
            type="file"
            id="photo_file"
            className="form-control"
            {...register("photo_file")}
          />
          <small className="text-danger">{errors.photo_file?.message}</small>
        </div>
      </>
    );
  };

  const getData = async () => {
    const newData = await getAllMaster("gallery",{m_gallery_categories_id:id});
    if (newData) {
      setData(newData);
    }
  };

  const getDataCategory = async () => {
    const newData = await getAllMaster("gallery-category");
    if (newData) {
      setDataCategory(
        newData?.map((item) => ({ value: item.id, label: item.name }))
      );
    }
  };

  useEffect(() => {
    getData();
    getDataCategory();
  }, []);

  return (
    <Layout title="Data Galeri">
      <Table
        columns={columns}
        data={data}
        getData={getData}
        getAllAction={getAllMaster}
        addAction={addMaster}
        addActionForm={addMasterForm}
        bodyForm={bodyForm}
        handleSubmit={handleSubmit}
        reset={reset}
        loading={loading}
        manualPagination
      />
    </Layout>
  );
}

export default GalleryPage;
