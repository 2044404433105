import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useContext, useEffect, useMemo, useState } from "react";
import { Badge } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Table from "./Table";
import Layout from "../../Layouts";
import AdminContext from "../../Services/Admin";
import { DateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
// import DateTimePicker from "react-datetime-picker";

const schema = yup
  .object({
    name: yup.string().required(),
    start: yup.string().required(),
    end: yup.string().required(),
  })
  .required();

function AgendaPage(params) {
  const { loading, getAllMaster, addMaster, addMasterForm } =
    useContext(AdminContext);
  const [data, setData] = useState([]);

  const {
    register,
    handleSubmit,
    reset,
    control,
    getValues,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Nama Agenda",
      },
      {
        accessorKey: "start",
        header: "Tanggal Mulai Agenda",
        Cell: ({ cell }) =>
          cell && moment(cell.getValue()).format("DD/MM/YYYY HH:mm:ss"),
      },
      {
        accessorKey: "end",
        header: "Tanggal Selesai Agenda",
        Cell: ({ cell }) =>
          cell && moment(cell.getValue()).format("DD/MM/YYYY HH:mm:ss"),
      },
    ],
    []
  );

  const bodyForm = () => {
    return (
      <>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Nama Agenda
          </label>
          <input
            type="text"
            id="name"
            className="form-control"
            {...register("name")}
            placeholder="Enter agenda name"
          />
          <small className="text-danger">{errors.name?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Deskripsi Agenda
          </label>
          <input
            type="text"
            id="description"
            className="form-control"
            {...register("description")}
            placeholder="Enter agenda description"
          />
          <small className="text-danger">{errors.description?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="start" className="form-label">
            Tanggal Mulai Agenda
          </label>
          <div>
            <Controller
              control={control}
              name="start"
              render={({ field: { onChange, value, ref } }) => (
                <DateTimePicker
                  disablePast
                  ampm={false}
                  value={value}
                  maxDateTime={getValues("end")}
                  onChange={(newValue) => onChange(newValue)}
                />
              )}
            />
          </div>
          <small className="text-danger">{errors.start?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="end" className="form-label">
            Tanggal Selesai Agenda
          </label>
          <div>
            <Controller
              control={control}
              name="end"
              render={({ field: { onChange, value, ref } }) => (
                <DateTimePicker
                  disablePast
                  ampm={false}
                  value={value}
                  minDateTime={getValues("start")}
                  onChange={(newValue) => onChange(newValue)}
                />
              )}
            />
          </div>
          <small className="text-danger">{errors.end?.message}</small>
        </div>
      </>
    );
  };

  const getData = async () => {
    const newData = await getAllMaster("agenda");
    if (newData) {
      setData(newData);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Layout title="Data Agenda">
      <Table
        columns={columns}
        data={data}
        getData={getData}
        getAllAction={getAllMaster}
        addAction={addMaster}
        addActionForm={addMasterForm}
        bodyForm={bodyForm}
        handleSubmit={handleSubmit}
        reset={reset}
        loading={loading}
        manualPagination
      />
    </Layout>
  );
}

export default AgendaPage;
