import MaterialReactTable from "material-react-table";
import { useEffect, useState } from "react";
import { Button, Modal, Stack } from "react-bootstrap";
import { Delete, Edit } from "@mui/icons-material";
import { toast } from "react-toastify";

function Table(params) {
  const [modal, setModal] = useState({
    show: false,
    id: "",
    type: "",
    data: {},
  });

  const handleClose = () => {
    setModal({ show: false, id: "", type: "", data: {} });
    params.reset({
      member_number: "",
      name: "",
      nickname: "",
      ktp_number: "",
      personal_whatsapp_number: "",
      emergency_contact_name: "",
      emergency_contact_number: "",
      email: "",
      gender: "",
      date_of_place: "",
      date_of_birth: "",
      ktp_address: "",
      domicile_address: "",
      religion: "",
      blood_type: "",
      marital_status: "",
      sim_number: "",
      license_plate_number: "",
      chassis_number: "",
      engine_number: "",
      m_chapters_id: "",
      ktp_file: "",
      sim_file: "",
      stnk_file: "",
      status: false,
    });
  };
  const handleShow = (id, type, data) =>
    setModal({ show: true, id: id, type: type, data: data });

  const deleteAction = async () => {
    const res = await params.addAction("member/delete/" + modal?.data?.id);
    if (!res?.err) {
      params.getData();
      toast.success("Delete Data Success");
      handleClose();
    }
  };

  const addAction = async (values) => {
    const formData = new FormData();
    formData.append("member_number", values.member_number);
    formData.append("name", values.name);
    formData.append("nickname", values.nickname);
    formData.append("ktp_number", values.ktp_number);
    formData.append(
      "personal_whatsapp_number",
      values.personal_whatsapp_number
    );
    formData.append("emergency_contact_name", values.emergency_contact_name);
    formData.append(
      "emergency_contact_number",
      values.emergency_contact_number
    );
    formData.append("email", values.email);
    formData.append("gender", values.gender);
    formData.append("date_of_place", values.date_of_place);
    formData.append("date_of_birth", values.date_of_birth);
    formData.append("ktp_address", values.ktp_address);
    formData.append("domicile_address", values.domicile_address);
    formData.append("religion", values.religion);
    formData.append("blood_type", values.blood_type);
    formData.append("marital_status", values.marital_status);
    formData.append("sim_number", values.sim_number);
    formData.append("license_plate_number", values.license_plate_number);
    formData.append("chassis_number", values.chassis_number);
    formData.append("engine_number", values.engine_number);
    formData.append("m_chapters_id", values.m_chapters_id);
    formData.append("ktp_file", values.ktp_file ? values.ktp_file[0] : "");
    formData.append("sim_file", values.sim_file ? values.sim_file[0] : "");
    formData.append("stnk_file", values.stnk_file ? values.stnk_file[0] : "");
    formData.append("status", values.status);

    let response = await params.addActionForm("member/create", formData);
    console.log(response);
    if (!response?.message) {
      params.getData();
      toast.success("Add Data Success");
      handleClose();
    }
  };

  const updateAction = async (values) => {
    const formData = new FormData();
    formData.append("member_number", values.member_number ?? "");
    formData.append("name", values.name ?? "");
    formData.append("nickname", values.nickname ?? "");
    formData.append("ktp_number", values.ktp_number ?? "");
    formData.append(
      "personal_whatsapp_number",
      values.personal_whatsapp_number ?? ""
    );
    formData.append(
      "emergency_contact_name",
      values.emergency_contact_name ?? ""
    );
    formData.append(
      "emergency_contact_number",
      values.emergency_contact_number ?? ""
    );
    formData.append("email", values.email ?? "");
    formData.append("gender", values.gender ?? "");
    formData.append("date_of_place", values.date_of_place ?? "");
    formData.append("date_of_birth", values.date_of_birth ?? "");
    formData.append("ktp_address", values.ktp_address ?? "");
    formData.append("domicile_address", values.domicile_address ?? "");
    formData.append("religion", values.religion ?? "");
    formData.append("blood_type", values.blood_type ?? "");
    formData.append("marital_status", values.marital_status ?? "");
    formData.append("sim_number", values.sim_number ?? "");
    formData.append("license_plate_number", values.license_plate_number ?? "");
    formData.append("chassis_number", values.chassis_number ?? "");
    formData.append("engine_number", values.engine_number ?? "");
    formData.append("m_chapters_id", values.m_chapters_id ?? "");
    formData.append("ktp_file", values.ktp_file ? values.ktp_file[0] : "");
    formData.append("sim_file", values.sim_file ? values.sim_file[0] : "");
    formData.append("stnk_file", values.stnk_file ? values.stnk_file[0] : "");
    formData.append("status", values.status??"");

    let response = await params.addActionForm(
      "member/update/" + modal?.data?.id,
      formData
    );
    if (!response?.err) {
      params.getData();
      toast.success("Update Data Success");
      handleClose();
    }
  };

  useEffect(() => {
    if (modal.type === "update" && modal.data) {
      const oldData = modal.data;
      const bodyRequest = {
        member_number: oldData?.member_number,
        name: oldData?.name,
        nickname: oldData?.nickname,
        ktp_number: oldData?.ktp_number,
        personal_whatsapp_number: oldData?.personal_whatsapp_number,
        emergency_contact_name: oldData?.emergency_contact_name,
        emergency_contact_number: oldData?.emergency_contact_number,
        email: oldData?.email,
        gender: oldData?.gender,
        date_of_place: oldData?.date_of_place,
        date_of_birth: oldData?.date_of_birth,
        ktp_address: oldData?.ktp_address,
        domicile_address: oldData?.domicile_address,
        religion: oldData?.religion,
        blood_type: oldData?.blood_type,
        marital_status: oldData?.marital_status,
        sim_number: oldData?.sim_number,
        license_plate_number: oldData?.license_plate_number,
        chassis_number: oldData?.chassis_number,
        engine_number: oldData?.engine_number,
        m_chapters_id: oldData?.m_chapters_id,
        status: oldData?.status ? true : false,
      };
      params.reset(bodyRequest);
    }
  }, [modal.type]);

  return (
    <>
      <MaterialReactTable
        muiTablePaperProps={{
          sx: {
            boxShadow: "none",
            borderRadius: "1rem",
          },
        }}
        muiTopToolbarProps={{
          sx: {
            borderRadius: "1rem",
          },
        }}
        muiBottomToolbarProps={{
          sx: {
            borderRadius: "1rem",
          },
        }}
        enableRowActions
        positionActionsColumn={"last"}
        renderRowActions={(row, index) => (
          <Stack direction="horizontal" gap={2}>
            <Button
              size="sm"
              variant="warning"
              className="py-1 px-2"
              onClick={() =>
                handleShow(row?.row?.id, "update", row?.row?.original)
              }
            >
              <Edit sx={{ fontSize: 14 }} />
            </Button>
            <Button
              size="sm"
              variant="danger"
              className="py-1 px-2"
              onClick={() =>
                handleShow(row?.row?.id, "delete", row?.row?.original)
              }
            >
              <Delete sx={{ fontSize: 14 }} />
            </Button>
          </Stack>
        )}
        state={{
          isLoading: params.loading,
        }}
        getRowId={(originalRow) => originalRow.id}
        renderTopToolbarCustomActions={({ table }) => (
          <Button
            variant="primary"
            onClick={() => {
              handleShow(null, "add");
            }}
            className="m-2"
          >
            Add New
          </Button>
        )}
        columns={params.columns}
        data={params.data}
      />
      <Modal
        show={modal.show && modal.type === "delete"}
        onHide={handleClose}
        centered
      >
        <Modal.Body className="h5 pb-1">
          Are you sure you want to delete this data?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={deleteAction}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={modal.show && modal.type === "add"}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add New Data
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="h5">{params.bodyForm()}</Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={params.handleSubmit(addAction)}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modal.show && modal.type === "update"}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Data
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="h5">{params.bodyForm()}</Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={params.handleSubmit(updateAction)}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Table;
